<template>
  <div class="app-mall-confirm container">
    <div
      class="content ex padding-20-0"
      v-if="form && form.items && form.items.length"
    >
      <el-steps :active="1" process-status="success" simple>
        <el-step title="我的购物车" />
        <el-step title="填写核对订单信息" />
        <el-step title="成功提交订单" />
      </el-steps>
      <div class="padding-20-0 fs-large">请填写并核对以下订单信息:</div>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="84px"
        label-suffix=":"
        label-position="right"
      >
        <div class="item-title">订单信息</div>
        <div class="item-content">
          <div class="h">
            <el-form-item label="预售订单">
              <div style="width: 180px">
                <el-checkbox v-model="form.isPrepare" />
              </div>
            </el-form-item>
            <el-form-item
              label="销售类型"
              label-width="100px"
              v-if="entType === 'kingkoil'"
            >
              <quick-select
                v-model="form.salesTypeId"
                filterable
                url="api/salesType"
                style="width: 180px"
                value-field="id"
                display-field="name"
                placeholder="选择销售类型"
              />
            </el-form-item>
          </div>

          <div class="h">
            <el-form-item prop="purchaseTime" label="单据日期">
              <el-date-picker
                v-model.trim="form.purchaseTime"
                :picker-options="purchaseTimeOptions"
                type="date"
                format="yyyy-MM-dd"
                value-format="timestamp"
                style="width: 180px"
                placeholder="下单日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item prop="contractNo" label="合同号" label-width="100px">
              <el-input
                v-model.trim="form.contractNo"
                :maxlength="30"
                placeholder="请输入商场合同号"
                style="width: 180px"
              />
            </el-form-item>
          </div>
        </div>

        <div class="item-title">客户信息</div>
        <div class="item-content">
          <div class="h s">
            <el-form-item prop="customerName" label="客户姓名">
              <el-input
                v-model="form.customerName"
                :maxlength="50"
                style="width: 180px"
              />
            </el-form-item>
            <el-form-item
              prop="customerPhone"
              label="手机号码"
              label-width="100px"
            >
              <el-input
                v-model="form.customerPhone"
                :maxlength="11"
                style="width: 180px"
              />
            </el-form-item>
          </div>
          <div class="h s">
            <el-form-item label="收货地址" prop="provinceCode">
              <quick-select
                v-model="form.provinceCode"
                :label.sync="form.provinceName"
                url="api/regionalDict/province"
                value-field="code"
                placeholder="选择省份"
                filterable
                style="width: 180px"
                @change="
                  form.cityCode = null;
                  form.districtCode = null;
                "
              />
            </el-form-item>
            <el-form-item
              label-width="10px"
              prop="cityCode"
              style="width: 185px"
            >
              <quick-select
                v-model="form.cityCode"
                :label.sync="form.cityName"
                :url="`api/regionalDict/parent/${form.provinceCode}`"
                value-field="code"
                placeholder="选择城市"
                filterable
                v-if="form.provinceCode"
                style="width: 100%"
                @change="form.districtCode = null"
              />
              <el-input disabled placeholder="请先选择省份" v-else />
            </el-form-item>
            <el-form-item
              label-width="10px"
              prop="districtCode"
              style="width: 185px"
            >
              <quick-select
                v-model="form.districtCode"
                :label.sync="form.districtName"
                :url="`api/regionalDict/parent/${form.cityCode}`"
                value-field="code"
                placeholder="选择地区"
                filterable
                v-if="form.cityCode"
                style="width: 100%"
              />
              <el-input disabled placeholder="请先选择城市" v-else />
            </el-form-item>
            <el-form-item
              label-width="10px"
              prop="customerAddress"
              class="flex"
            >
              <el-input
                v-model="form.customerAddress"
                :maxlength="100"
                class="flex"
                placeholder="请输入详细地址"
              />
            </el-form-item>
          </div>
        </div>

        <div class="item-title">门店信息</div>
        <div class="item-content">
          <div class="h s">
            <el-form-item label="销售门店" prop="shopId">
              <quick-select
                v-model="form.shopId"
                filterable
                :url="`api/shop?enabled=true`"
                value-field="id"
                display-field="shopName"
                placeholder="选择销售门店"
                auto-select-first-option
                @change="
                  form.salesmanId = null;
                  loadStockInfos();
                "
                disabled
                style="width: 460px"
              />
            </el-form-item>
            <el-form-item label="销售人员" label-width="100px">
              <quick-select
                v-model="form.salesmanId"
                v-if="form.shopId"
                filterable
                style="width: 180px"
                :url="`api/users?shopId=${form.shopId}`"
                value-field="id"
                display-field="userRealName"
                placeholder="选择销售人员"
                @change="handleSalemanChange"
              />
              <el-input
                disabled
                placeholder="请先选择销售门店"
                v-else
                style="width: 180px"
              />
            </el-form-item>
          </div>
        </div>

        <div class="item-title">商品清单</div>
        <div class="item-content">
          <el-table :data="form.items" border>
            <el-table-column label="商品信息" min-width="360">
              <div class="h c" slot-scope="scope">
                <div class="lh-150 flex">
                  <div>
                    <a
                      :href="`/mall/item/${encode(scope.row.spuId)}`"
                      target="dup"
                    >
                      <b>{{ scope.row.goodsName }}</b>
                    </a>
                  </div>
                  <div class="fc-g">
                    {{ $goodsSpecConvert(scope.row.specs) }}
                  </div>
                  <div class="h c">
                    <span class="fc-g">统一零售价：</span>
                    <b class="fc-e">{{ $price(scope.row.price) }}</b>
                    <el-divider direction="vertical" />
                    <span class="fc-g">可售数量：</span>
                    <div v-if="scope.row._stockInfo.loading" class="fc-g">
                      正在计算中…
                    </div>
                    <div v-else-if="scope.row._stockInfo.error" class="h c">
                      <span class="fc-e">{{ scope.row._stockInfo.error }}</span>
                      <el-button
                        size="mini"
                        type="text"
                        icon="el-icon-refresh"
                        @click="loadStockInfo(scope.row)"
                      ></el-button>
                    </div>
                    <div v-else-if="scope.row._stockInfo.values" class="h c">
                      <el-popover placement="top" width="400" trigger="hover">
                        <div class="h c">
                          <span
                            >库存数量：{{
                              scope.row._stockInfo.values.warehouseCount
                            }}</span
                          >
                          <el-divider direction="vertical" />
                          <span
                            >待发货数量：{{
                              scope.row._stockInfo.values.saleCount
                            }}</span
                          >
                          <el-divider direction="vertical" />
                          <span
                            >在途数量：{{
                              scope.row._stockInfo.values.purchaseCount
                            }}</span
                          >
                        </div>
                        <b
                          slot="reference"
                          class="fc-e"
                          style="cursor: pointer"
                          >{{ calcStock(scope.row) }}</b
                        >
                      </el-popover>
                    </div>
                  </div>
                </div>
                <el-select
                  size="mini"
                  v-model="scope.row.itemType"
                  @change="handleGoodaItemTypeChange(scope.row)"
                  style="width: 80px; margin-left: 10px"
                >
                  <el-option
                    v-for="(v, k) in goodsItemTypes"
                    :key="k"
                    :label="v.label"
                    :value="k"
                  />
                </el-select>
              </div>
            </el-table-column>

            <el-table-column label="销售价" width="140" align="center">
              <template slot-scope="scope">
                <price-input
                  v-model="scope.row.realPrice"
                  :min="0"
                  size="mini"
                  style="width: 100%"
                  @change="calcRealPrice(scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column label="折扣率" width="120" align="center">
              <template slot-scope="scope">
                <percent-input
                  v-model="scope.row.discountRate"
                  size="mini"
                  :base="10000"
                  style="width: 100%"
                  @change="handleChangeDiscountRate(scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column label="数量" width="100" align="center">
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.quantity"
                  :min="1"
                  :max="9999"
                  :step="1"
                  :precision="0"
                  size="mini"
                  controls-position="right"
                  @change="$checkNaN(scope.row, 'quantity')"
                  style="width: 100%"
                />
              </template>
            </el-table-column>
            <el-table-column label="现场提货" width="80" align="center">
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.sendType"
                  :true-label="0"
                  :false-label="1"
                />
              </template>
            </el-table-column>
            <el-table-column label="交货时间" width="140" align="center">
              <template slot-scope="scope">
                <el-date-picker
                  class="not-clear"
                  v-model="scope.row.deliveryDate"                  
                  :clearable="false"
                  size="mini"
                  value-format="timestamp"
                  style="width: 100%"
                />
              </template>
            </el-table-column>
            <el-table-column label="备注" width="160" align="center">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.info"
                  size="mini"
                  :maxlength="100"
                />
              </template>
            </el-table-column>
          </el-table>
          <el-form-item
            label="整单优惠"
            v-if="promotions && promotions.length"
            style="margin-top: 10px"
          >
            <quick-select
              v-model="form.promotionId"
              :options="promotions"
              display-field="_label"
              clearable
              empty-text="没有可以参与的促销活动"
              placeholder="请选择促销活动"
              @change="loadPromotions"
              style="width: 480px"
            />
          </el-form-item>
        </div>

        <!-- <template v-if="form._gifts && form._gifts.length">
          <div class="item-title">赠品列表</div>
          <div class="item-content">
            <el-table :data="form._gifts" border>
              <el-table-column label="赠品" min-width="360">
                <div class="lh-150" slot-scope="scope">
                  <div>
                    <b>{{scope.row.goodsName}}</b>
                  </div>
                  <div class="fc-g">{{$goodsSpecConvert(scope.row.specs)}}</div>
                </div>
              </el-table-column>
              <el-table-column label="单价" width="100" align="right">
                <template slot-scope="scope">
                  <div class="lh-150 v e">
                    <b class="fc-e">{{$price(scope.row.realPrice)}}</b>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="quantity" label="数量" align="center" width="70" />
              <el-table-column label="期待发货时间" width="160">
                <template slot-scope="scope">
                  <el-date-picker v-model="scope.row.shipDate" :clearable="false" size="mini" value-format="timestamp" style="width: 100%" />
                </template>
              </el-table-column>
              <el-table-column label="备注" width="160">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.info" size="mini" :maxlength="100" />
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>-->

        <div class="item-title">其他</div>
        <div class="item-content">
          <el-form-item label="优惠券">
            <el-input
              v-model="form.couponRemark"
              :maxlength="50"
              placeholder="请输入优惠券名称"
              style="width: 370px"
            />
          </el-form-item>
          <el-form-item prop="source" label="订单来源">
            <el-input
              v-model="form.source"
              :maxlength="200"
              placeholder="请填写订单来源"
            />
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              v-model="form.info"
              :maxlength="200"
              placeholder="有其他要求或需要说明的情况，请在备注中说明"
              resize="none"
              :rows="5"
            />
          </el-form-item>
          <el-form-item label="附件">
            <file-uploader
              :entity-id="form.fileId"
              folder="retail/attachments"
              accept
              multiple
              :max-count="9"
              :thumbnail-size="80"
            />
          </el-form-item>
        </div>

        <div class="item-content summary v e">
          <div class="h c">
            <div v-html="counts._str"></div>
            <template v-if="counts.discount > 0">
              <el-divider direction="vertical" />
              <span>优惠金额：</span>
              <b class="fc-e fs-huge">{{ $price(counts.discount) }}</b>
            </template>
            <el-divider direction="vertical" />
            <span>订单金额：</span>
            <b class="fc-s fs-huge">{{ $price(counts.amount) }}</b>
          </div>

          <div style="margin-top: 12px">
            发送至：{{ form.provinceName }}{{ form.cityName
            }}{{ form.districtName }}{{ form.recAddress }}&emsp;&emsp;收货人：{{
              form.customerName
            }}（{{ form.customerPhone }}）
          </div>
        </div>
        <div class="padding-10-0 ta-r">
          <el-button
            size="large"
            type="danger"
            :loading="submiting"
            @click="handleSubmit"
          >
            <b class="fs-huge">提交订单</b>
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getGoodsByShopId } from "@/api/warehouse";
import { add } from "@/api/shopOrder";
import { getSkuRetailPrice } from "@/api/goods";
import request from "@/utils/request";
import { mapGetters } from "vuex";
import { getStatementType } from "@/api/capitalPool";
export default {
  props: {
    shopId: Number | String,
  },
  data() {
    const checkItemInfos = function (rule, value, callback) {
      let err = null;
      if (value && value.length) {
        for (let i = 0, l = value.length; i < l; i++) {
          if (!value[i].deliveryDate) {
            err = "请选择交货日期";
            break;
          }
        }
      }
      if (err) {
        callback(new Error(err));
      } else {
        callback();
      }
    };
    return {
      entType: null,
      form: null,
      submiting: false,
      maxRebate: null,
      now: null,
      purchaseTimeOptions: {
        disabledDate: (d) => {
          let nd = d.getTime();
          return nd > this.now;
        },
      },
      goodsItemTypes: {
        sale: { label: "商品", type: "" },
        gift: { label: "赠品", type: "warning" },
        simple: { label: "出样", type: "info" },
      },
      rules: {
        provinceCode: [
          { required: true, message: "请选择省份", trigger: "change" },
        ],
        cityCode: [
          { required: true, message: "请选择城市", trigger: "change" },
        ],
        districtCode: [
          { required: true, message: "请选择地区", trigger: "change" },
        ],
        customerAddress: [
          { required: true, message: "收货地址为必填项", trigger: "blur" },
        ],
        customerName: [
          { required: true, message: "请填写客户名称", trigger: "blur" },
        ],
        shopId: [{ required: true, message: "请选择门店", trigger: "blur" }],
        salesmanId: [{ required: true, message: "请选择销售人员" }],
        customerPhone: [
          { required: true, message: "请填写客户手机号码" },
          {
            pattern: /^\d{11}$/gi,
            message: "手机号码格式不正确",
          },
        ],
        items: [
          { required: true, message: "请选择要销售的商品" },
          { type: "array", min: 1, message: "请选择要销售的商品" },
          { validator: checkItemInfos },
        ],
        prePay: [
          { required: true, message: "请填写已付款金额", trigger: "blur" },
        ],
        auditRemark: [{ required: true, message: "请填写审批备注信息" }],
        warehouseId: [
          { required: true, message: "请选择发货库房", trigger: "change" },
        ],
        contractNo: [{ required: true, message: "请填写商城合同号" }],
      },
      promotions: [],
    };
  },
  mounted() {
    getStatementType().then((res) => {
      this.entType = res;
    });
  },
  computed: {
    ...mapGetters(["user"]),
    counts() {
      let _nums = 0,
        _giftNums = 0,
        _str = "",
        discount = 0,
        amount = 0;
      if (this.form) {
        if (this.form.items && this.form.items.length) {
          this.form.items.forEach((o) => {
            _nums += o.quantity;
            let dm = o.price - o.realPrice;
            if (dm < 0) dm = 0;
            amount += o.realPrice * (o.quantity - o.cancelCount);
            discount += dm * (o.quantity - o.cancelCount);
          });
          _str += `共<b>${this.form.items.length}</b>种<b>${_nums}</b>件商品`;
        }
      }
      return {
        _str: _str,
        _giftNums: _giftNums,
        _nums: _nums,
        amount: amount,
        discount: discount,
      };
    },
  },
  methods: {
    calcRealPrice(row) {
      row.discountRate = (row.realPrice / row.price) * 10000;
    },
    calcStock(row) {
      let r =
        row._stockInfo.values.warehouseCount - row._stockInfo.values.saleCount;
      if (r < 0) r = 0;
      return r;
    },
    handleSalemanChange(k, v, obj) {
      this.form.salesmanName = obj.userRealName;
    },
    handleChangeDiscountRate(row) {
      row.realPrice = row.price * (row.discountRate / 10000);
    },
    handleGoodaItemTypeChange(row) {
      if (row.itemType === "sale") {
        this.loadGoodsPrice(row);
      } else {
        row.realPrice = 0;
        row.price = 0;
      }
    },
    // handlePurchaseTimeChange() {
    //   this.form.items.forEach(o => {
    //     if (o.sendType === 0) {
    //       this.calcMinDeliveryDate(o);
    //     }
    //   });
    // },

    // genGoodsShipDatePickerOptions(row) {
    //   let minDate = row._minDeliveryDate;
    //   return {
    //     disabledDate: (date) => {
    //       return date.getTime() < minDate;
    //     },
    //   };
    // },

    loadStockInfos() {
      if (
        this.form &&
        this.form.items &&
        this.form.items.length &&
        this.form.shopId
      ) {
        this.form.items.forEach((o) => {
          this.loadStockInfo(o);
        });
      }
    },
    loadStockInfo(g) {
      if (g && g.goodsId && this.form.shopId) {
        g._stockInfo.loading = true;
        g._stockInfo.error = false;
        g._stockInfo.values = null;
        getGoodsByShopId(g.goodsId, this.form.shopId)
          .then((res) => {
            g._stockInfo.values = res;
          })
          .catch((err) => {
            g._stockInfo.error = "获取失败";
          })
          .finally((_) => {
            g._stockInfo.loading = false;
          });
      }
    },
    loadGoodsPrice(row) {
      getSkuRetailPrice(this.shopId, row.goodsId)
        .then((res) => {
          if (isNaN(res)) {
            row.price = 0;
            row.realPrice = 0;
          } else {
            row.realPrice = row.price = parseInt(res);
          }
        })
        .catch((err) => {
          row.price = 0;
          row.realPrice = 0;
        });
    },
    // calcMinDeliveryDate(o) {
    //   if (o.sendType === 0) {
    //     let d = new Date(this.form && this.form.purchaseTime ? this.form.purchaseTime : this.$now.get());
    //     o._minDeliveryDate = new Date(
    //       d.getFullYear(),
    //       d.getMonth(),
    //       d.getDate()
    //     ).getTime();
    //     o.deliveryDate = o._minDeliveryDate;
    //   } else {
    //     request({
    //       url: "api/workday",
    //       method: "get",
    //       params: {
    //         days: o.deliveryCycle || 7,
    //       },
    //     }).then((res) => {
    //       o._minDeliveryDate = res;
    //       if (!o.deliveryDate || o.deliveryDate < res) o.deliveryDate = res;
    //     });
    //   }
    // },
    encode(val) {
      return btoa(val);
    },
    generateForm() {
      let items = JSON.parse(
        window.localStorage.getItem("retail-cart-confirm") || "[]"
      );

      if (items && items.length) {
        items.forEach((o) => {
          o.itemType = "sale";
          o._promotions = [];
          o._stockInfo = {
            loading: false,
            error: false,
            values: null,
          };
          let now = new Date(this.$now.get());
          let dc = o.deliveryCycle || 7;
          o.deliveryDate = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + dc
          ).getTime();
          this.loadGoodsPrice(o);
          // this.calcMinDeliveryDate(o);
        });

        this.form = {
          items: items,
          source: null,
          formCode: null,
          isPrepare: false,
          purchaseTime: this.now,
          customerId: null,
          customerName: null,
          customerPhone: null,
          provinceCode: this.user.provinceCode,
          provinceName: null,
          cityCode: this.user.cityCode,
          cityName: null,
          districtCode: null,
          districtName: null,
          customerAddress: null,
          prePay: 0,
          payType: 1,
          payItems: [],
          couponRemark: "",
          info: null,
          fileId: this.$uuid(),
          auditRemark: null,
          status: "input",
          shopId: this.shopId,
        };
        this.$nextTick((_) => {
          this.$refs.form && this.$refs.form.clearValidate();
          this.loadStockInfos();
        });
      } else {
        this.$router.replace("/mall/retail/cart");
      }
    },
    convertForm() {
      return new Promise((resolve, reject) => {
        let form = JSON.parse(JSON.stringify(this.form));
        let hasNoSelectPromotion =
          this.promotions && this.promotions.length && !this.form.promotionId;
        if (!hasNoSelectPromotion) {
          for (let i = 0, l = form.items.length; i < l; i++) {
            let o = form.items[i];
            if (
              !this.form.promotionId &&
              o._promotions &&
              o._promotions.length &&
              !o.promotionId
            ) {
              hasNoSelectPromotion = true;
              break;
            }
          }
        }
        form.items = form.items.concat(form._gifts);
        delete form._gifts;
        if (hasNoSelectPromotion) {
          this.$confirm(
            "当前订单还有单品促销或者整单优惠活动可以参加。<br />确定不参与活动并继续操作吗？",
            "温馨提示",
            {
              type: "warning",
              dangerouslyUseHTMLString: true,
            }
          )
            .then((_) => {
              resolve(form);
            })
            .catch((_) => {
              reject();
            });
        } else {
          resolve(form);
        }
      });
    },

    handleSubmit() {
      this.$refs.form &&
        this.$refs.form.validate().then((_) => {
          this.submiting = true;
          add(this.form)
            .then((res) => {
              this.$notify({
                title: `提交订单成功`,
                type: "success",
                duration: 2500,
              });
              window.mall.cart.clear(false);
              window.localStorage.setItem(
                "retail-cart-complete-order",
                JSON.stringify(res)
              );
              window.localStorage.removeItem("retail-cart-confirm");
              this.$router.replace({
                path: "/mall/retail/cart/complete/" + res.id,
              });
            })
            .finally((_) => {
              this.submiting = false;
            });
        });
    },
  },
  activated() {
    this.now = this.$now.get();
    this.generateForm();
  },
};
</script>